<!-- 真人视讯 -->
<template>
  <div class="content">
    <div class="mod-games">
      <div class="mod-banner" style="height: 461.205px">
        <!--焦点图-->
        <Banner />
        <!-- <div class="slide-wrap" id="slides">
          <div class="num"><span class="current"></span></div>
          <ul>
            <li style="display: block">
              <a
                ><img
                  style="max-width: none"
                  src="/in/fimg/i20220303d8004918644c79a41e482e289d58b2.png"
              /></a>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="function-page">
        <h4>Vendors:</h4>
        <div class="casino-menu">
          <ul id="platform_container" class="games-hd-menu clearfix">
            <template v-for="item in newList">
              <li
                class="menu"
                :class="{ current: curId === citem.id }"
                v-for="(citem, cindex) in item"
                :key="cindex"
                @click="tapGame(citem)"
              >
                {{ citem.name }}
              </li>
            </template>
          </ul>
        </div>
        <h4>Game Filters:</h4>
        <ul class="games-sub-menu clearfix">
          <li :class="{ current: activeTab == 1 }" @click="pageChange">
            {{ $t("gamePage.text0") }}
          </li>
          <li
            v-show="activeGame"
            :class="{ current: activeTab == 2 }"
            @click="getRecommend"
          >
            {{ $t("gamePage.text2") }}
          </li>
        </ul>

        <div class="games-panes">
          <div class="games-platform-item pr">
            <!--<div class="search-wrap clearfix">
                        <input type="text" class="search-text" />
                        <a href="javascript:void(0);" class="search-button"></a>
                    </div>-->
            <div class="clear"></div>
            <div class="games-list">
              <div class="games-item clearfix">
                <ul class="clearfix">
                  <li v-for="item in showList" :key="item.id" @click="_getToken(item,1)">
                    <img loading="lazy" v-lazy="systemImgHost + item.pictureUrl" />
                    <div v-show="item.nameEn" class="game-name">
                      {{ item.nameEn }}
                    </div>
                    <div class="game-name">
                      {{ item.name || "--" }}
                      <span class="game-platform-name">Pragmatic Play</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="games-text page-text">
        <!-- Slots Page within <div class="games-text page-text">-->
        <div class="layout text-left" :class="{ 'layout-height': moreShow }">
          <p>The tables at Fun88’s online casino are really hot!</p>

          <p>
            Our online casino oozes charm and class and offers a great variety
            of games – online slots, jackpots, video poker along with bonuses,
            promotions and more. Don’t miss a chance to play classic casino
            games like live roulette, blackjack, baccarat, along with the
            classic card games Andar Bahar, Teen Patti in our live casino.
          </p>

          <h1 class="mt-40">The best online casino slot games</h1>

          <p class="mt-10">
            Gaming thrills is what Fun88 aims to provide as it specialises in
            online casino entertainment across the world. The gaming tables
            offer many options in casino games – from online slots, popular
            slots, jackpots, video slots, table and card games – running
            parallely with our live casino offering. Whether you want to play a
            demo version or gamble with this elevated art form of gaming, we
            have high-quality games available for you!
          </p>

          <p>
            Our gaming gallery in the online slots is laid out for ease of use
            and with intuitive navigation. Our casino games are powered by the
            world’s best software providers including Microgaming, Playtech and
            Netent that promise safety.
          </p>

          <p>
            At Fun88, we are constantly enhancing the gaming experience in our
            online slots. The casino games, gaming categories and choices we
            offer our customers are constantly updated with new additions and
            tweaks. No doubt, Fun88’s platform provides the
            <a
              href="https://blog.fun88.com/top-online-slot-games-card-games-of-2022-thatll-make-you-win-big/"
              target="_blank"
              >best casino slot games online </a
            >to a wide variety of players in India.
          </p>

          <p>
            What’s more, our customers can fine-tune their bets and personalise
            their gameplay in our
            <router-link to="/live" target="_blank"
              >online casino</router-link
            >. We put our focus on ensuring a safe and secure environment
            alongside a thrilling gameplay experience. We start your online
            casino play with us with top-notch bonuses and promotions. Manage
            your account in our online casino with unmatched security, and on
            winning, your money is instantly credited into your account with a
            fast and reliable withdrawal process.
          </p>

          <h2 class="mt-40">Play the best online casino slots on FUN88</h2>

          <h3 class="mt-10 text-left font-size-20">Online slots</h3>
          <p class="mt-10">
            The most popular genre in the online casino – Fun88 offers you a
            range of slots – from the traditional to the shiny and new video
            slots. Exciting graphics, sound effects and jackpots, along with
            bonus features and gambling options, are a trademark of our online
            casino games. Starting from the classic 3-reel slots, which vie for
            your attention along with the slick 5-reel games for high
            stake-betting like Five Reel Drive, you can try your hand at the
            luxury themed Break Da Bank Again or enjoy the eye candy as you
            click your way to winning with Playboy! Our popular online video
            slots include Breakaway Deluxe, Immortal Romance and The Great
            Albini and have multiple pay lines, a wide variety of bonus symbols,
            and multiple interactive mini-games! Keeping you on the edge of your
            seats, online slot games offer you a chance to strike lucky right
            from your first spin.
          </p>
          <h3 class="mt-10 text-left font-size-20">Jackpot slots</h3>
          <p class="mt-10">
            Are you looking for some online casino action? Fun88’s jackpot slots
            are available online with big stakes, bigger excitement and winning
            opportunities. The earnings build with every player, and finally,
            one lucky recipient can claim the grand prize. Enjoy a spin in the
            retro lane with the forever favourites like 3-reel Cash Splash and
            5-reel Fruit Fiesta, or the winnings on the 25 pay line slots of the
            best Microgaming slot game Mega Moolah -- our progressive jackpot
            slot games are perfect for the seasoned high-stake gamer!
          </p>

          <h3 class="mt-10 text-left font-size-20">Online table games</h3>
          <p class="mt-10">
            Table games are the way to go if you would like to indulge your
            lucky chance streak! From American, European to Live selections, our
            expert tips will help you get the best of the roulette, craps and
            Baccarat!
          </p>
          <p>
            So, what are you waiting for?
            <router-link href="/author?type=1" target="_blank"
              >Sign up</router-link
            >
            on Fun88 and play various games, like online slots, jackpots, and
            video poker. We offer you amazing opportunities for betting and
            winning!
          </p>
          <h2 class="mt-40">Why should you play online slot games on FUN88?</h2>
          <p class="mt-10">
            At Fun88, we provide you with an immersive gaming experience for
            playing online slot games with the help of exciting graphics and
            sound effects. That said, there are several other reasons why Fun88
            should be your preferred platform to play online slots.
          </p>
          <p>
            <b style="color: #00a6ff">Huge selection of games: </b> At Fun88,
            you’ll find a huge range of online slot games to choose from. Be it
            Five Reel Drive, Break Da Bank Again, Immortal Romance, The Great
            Albini, Irish PotLuck, or Hotline, you can find a lot of options to
            choose from.
          </p>

          <p>
            <b style="color: #00a6ff">Games meant for everyone: </b> Online slot
            games are meant for everyone unlike sports betting and card games,
            which require a certain level of skill. In the case of online slots,
            winning or losing is down to a chance, so anyone can play. That
            said, it’s important to provide interesting slot games, which people
            like. This is where Fun88 excels by providing immersive online slots
            for all kinds of players.
          </p>

          <p>
            <b style="color: #00a6ff"
              >Amazing offers and bonuses for slot players:
            </b>
            Fun88 offers some of the best bonuses, prizes, and promotions for
            online slot players, which you can check by going to this
            <router-link to="/promo" target="_blank"
              >link</router-link
            >. All newly registered players can avail first-time bonus on their
            deposit amount.
          </p>

          <p>
            <b style="color: #00a6ff"> Safe and secure payment modes: </b> On
            our platform, you can find several industry-leading payment modes to
            deposit and withdraw your funds, including Netbanking,
            Cryptocurrency, UPI, Google Pay, VISA, MasterCard, Ecopayz,
            Astropay, and Sticpay. These are some of the most secure and safest
            modes for payment out there. Moreover, you can deposit in Indian
            Rupee (INR) to play online slot games on Fun88.
          </p>

          <p>
            <b style="color: #00a6ff">
              User-friendly website and a mobile app:
            </b>
            Last but not least, you can play online slot games with an extremely
            user-friendly website, which ensures a blazing fast experience. Just
            register
            <router-link to="/author?type=1" target="_blank"
              >here</router-link
            >
            and start playing in an instant. However, if you want to play on the
            go, then you can download our app, which is available in both
            Android and iOS variants.
          </p>

          <div style="max-width: 100%; padding: 2em 0 0">
            <p style="text-align: center; padding-bottom: 0">
              To download, scan the QR code given below from your mobile or open
              the
              <a :href="downloadUrl"
                target="_blank"
                style="color: #00a6ff"
                >app download link</a
              >.
            </p>
            <div class="row download-app-row mt-20">
              <div class="col col-lg text-right mb-20">
                  <div id="qrcode" style="width: 158px; float: right"></div>
              </div>
              <div class="col col-lg">
                <p
                  style="font-size: 28px; font-weight: bold; padding-bottom: 0"
                >
                  FUN88 Main App
                </p>
                <p style="font-weight: light; padding-bottom: 0">
                  Download the app now
                </p>
                <p style="font-weight: light; padding-bottom: 0">
                  <a
                    :href="downloadUrl"
                    target="_blank"
                    style="color: #00a6ff"
                    >{{ downloadUrl }}</a
                  >
                </p>
              </div>
            </div>
          </div>
          <h2 class="mt-40">
            Frequently asked questions (FAQs) on online casino slots
          </h2>
          <div class="faq mt-20">
            <details open="">
              <summary>How do I play online slot games?</summary>
              <div class="faq__content">
                <p>
                  You don’t need a specific skill to play an online slot game.
                  These are the games of chance. Just click the spin button and
                  our software will do the rest. To win, you’ve to line a
                  certain number of matching symbols on a pay line. This is the
                  basic concept of a slot game. Various gaming platforms add
                  tons of variations to make the games more interesting.
                </p>
              </div>
            </details>
            <details>
              <summary>What is a payline?</summary>
              <div class="faq__content">
                <p>
                  To win while playing an online slot game, you have to match
                  identical symbols in a predetermined order on the reels, which
                  are called paylines. There are different kinds of paylines in
                  each game. Paylines can be in a row or zig-zag pattern. They
                  can also be in a V shape or they can be in a diagonal form as
                  well. It’s always better to read the rules of a game about
                  paylines before starting to play.
                </p>
              </div>
            </details>
            <details>
              <summary>
                Are there slot games, which do not use paylines?
              </summary>
              <div class="faq__content">
                <p>
                  Yes, indeed. There are slot games that do not require you to
                  have matching symbols on a payline. In such cases, typically,
                  they pay based on the number of symbols you’ve managed to land
                  on the reels.
                </p>
              </div>
            </details>
            <details>
              <summary>What is a wild symbol in a slot game?</summary>
              <div class="faq__content">
                <p>
                  As in card games, a wild symbol is the one which can be used
                  to fill in for any other symbols missing in a combination.
                  That said, in most games, a wild symbol doesn’t substitute for
                  free spins, scatters, and bonus symbols. So, do read about the
                  rules of a game in detail to know which symbols can be
                  replaced by a wild symbol.
                </p>
              </div>
            </details>
            <details>
              <summary>What is a scatter symbol in a slot game?</summary>
              <div class="faq__content">
                <p>
                  A scatter symbol is the one that appears outside of a winning
                  line. Even though a scatter symbol doesn’t appear on a winning
                  line, it is considered as a part of a winning combo.
                  Therefore, scatter symbols enable players to win a game
                  without having to match all the symbols on a payline.
                </p>
              </div>
            </details>
            <details>
              <summary>Why do we have free spins in online slots?</summary>
              <div class="faq__content">
                <p>
                  Free spins are used by casino operators to motivate players,
                  especially when they don’t win. By offering a free spin,
                  players get a chance to make money without betting more cash,
                  which keeps them in good spirits.
                </p>
              </div>
            </details>
            <details>
              <summary>
                How do I know that I have a fair chance of winning while playing
                online slots at Fun88?
              </summary>
              <div class="faq__content">
                <p>
                  At Fun88, we take every possible care to ensure that all our
                  players have a fair chance of winning while playing online
                  slots. That’s why we ensure that online slots are really
                  random by using fair random number generators (RNGs). When you
                  play an online slot game at Fun88, you can rest assured that
                  all players have the same probability of winning or losing.
                </p>
              </div>
            </details>
            <details>
              <summary>
                Does Fun88 offer exactly the same variety of online slot games
                from its website compared to its mobile app?
              </summary>
              <div class="faq__content">
                <p>
                  Yes, we offer the same range of online slot games whether
                  you’re playing at our website or through our mobile app. We
                  know that a lot of players are always on the move; therefore,
                  we made sure that no matter which device you use, you can play
                  the same set of online slots.
                </p>
              </div>
            </details>
            <details>
              <summary>
                Why do professional bettors tend to play other online casino
                games and not online slots?
              </summary>
              <div class="faq__content">
                <p>
                  Professional bettors prefer playing games, which provide them
                  with a certain level of challenge to test their skills. That’s
                  the reason, they prefer other online casino games, like Teen
                  Patti and Poker. As online slot games are purely a function of
                  chance, they are more meant for those players, who are only
                  playing for thrill. That said, at times, even professional
                  bettors play online slot games to have fun.
                </p>
              </div>
            </details>
          </div>
        </div>
        <div @click="moreChange" class="read-more">READ MORE</div>
      </div>
    </div>
  </div>
</template>
<script>
import { _getToken } from "../../core/comp/common";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import Banner from "../../core/components/Banner.vue";
import {
  _thirdList,
  _searchGameAction,
  _recommendGameByIDAction,
} from "../../core/comp/pages/games";
import { _createQRcode, _downloadApp } from "../../core/utils/utils";
export default {
    components: {
        Banner
    },
  data() {
    return {
      curId: "",
      loading: false,
      searchText: "", // 当前搜索的内容
      searchList: [], // 搜索结果

      activeGame: "", // 当前激活的游戏平台ID
      activeTab: 1, // 当前激活的分类 1-全部  2-热门游戏
      newList: [], // 分割之后的新数组
      pages: {
        // 分页管理
        currPage: 1,
        pageSize: 9999,
      },
      moreShow: true,
      downloadUrl: '',
    };
  },
  watch: {
    activeGame(val) {
      // 监听平台变化
      if (this.gameElecPages[val]) {
        // 当平台变化时发现该平台有缓存的数据 则直接展示
        this.pages = JSON.parse(JSON.stringify(this.gameElecPages[val]));
      }
    },
    // lists (val) { // 监听二级分类数据变化，当变化时默认获取第一个元素的子游戏列表
    //     if (val && val[0]) {
    //         console.log(1)
    //         this.tapGame(val[0]) // 当前页刷新时的初始化
    //     }
    // },
    $route: {
      handler: function (val) {
        if (val) {
          let id = val.query.id;
          if (id != 0) {
            let obj = this.lists.find((item) => {
              return item.id == id;
            });
            this.tapGame(obj); // 初始化
          } else {
            this.tapGame(this.lists[0]); // 初始化
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.splitList();
    this.qrcodeChange();
  },
  computed: {
    showList() {
      // 展示的游戏列表
      if (this.activeGame) {
        // 全部游戏列表
        if (this.activeTab == 1)
          return this.gameElecLists[this.activeGame] || [];
        // 热门游戏列表
        if (this.activeTab == 2)
          return this.gameElecRecommen[this.activeGame] || [];
      }
      return this.searchList; // 搜索结果
    },
    listTotal() {
      // 列表总数
      if (this.gameElecPages[this.activeGame])
        return this.gameElecPages[this.activeGame].total;
      return 0;
    },
    lists() {
      // 二级导航
      return this.gameMenu.arr_dz || [];
    },
    ...mapGetters([
      "isLogin",
      "gameMenu", // 游戏分类
      "gameElecLists", // 三级游戏 全部列
      "gameElecPages", // 三级游戏 全部列表的页面状态
      "gameElecRecommen", // 三级游戏 热门列
      "systemImgHost",
    ]),
  },
  methods: {
      qrcodeChange() {
      this.downloadUrl = _downloadApp();
      _createQRcode({
        id: "qrcode",
        content: this.downloadUrl,
        width: 158,
        height: 158,
      });
    },
    moreChange() {
      this.moreShow = !this.moreShow;
    },
    _getToken,
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 8) {
        this.newList.push(this.lists.slice(i, i + 8));
      }
    },
    // 搜索
    search() {
      if (!this.isLogin) return Message.warning(this.$t("tipsMessage.text1"));
      if (this.searchText === "") return;
      this.curId = "";
      this.activeGame = "";
      this.activeTab = 1;
      console.log("---搜索", this.searchText);
      setTimeout(() => {
        this.searchList = [];
        _searchGameAction(this.searchText).then((res) => {
          if (res) this.searchList = res;
        });
      }, 0);
    },
    // 翻页
    pageChange() {
      this.activeTab = 1; // 锁定为全部游戏
      this.tapGame(this.lists.find((item) => item.id == this.activeGame));
    },
    // 点击游戏平台
    tapGame(item) {
      if (this.loading) return;
      this.curId = item.id;
      this.activeTab = 1;
      console.log("---点击了", item);
      this.activeGame = item.id;
      setTimeout(() => {
        // 如果发现当前缓存的是正要请求的该页数据，则不用发送请求，直接用缓存即可，可以减少大量请求
        if (
          this.gameElecPages[this.activeGame] &&
          this.gameElecPages[this.activeGame].currPage == this.pages.currPage
        )
          return;
        this.loading = true;
        _thirdList({ item, pages: this.pages }).then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
      }, 0);
    },
    // 获取推荐游戏
    getRecommend() {
      this.activeTab = 2; // 锁定为推荐游戏
      const item = this.lists.find((item) => item.id == this.activeGame);
      setTimeout(() => {
        console.log("---推荐", item);
        _recommendGameByIDAction(item);
      }, 0);
    },
  },
};
</script>
<style lang="less" scoped>
.mod-games {
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
  .function-page {
    padding: 30px 30px 0;
    margin: 0;
    h4 {
      font-size: 24px;
      font-weight: bold;
      padding: 0 0;
      margin: 0 0;
    }
    .casino-menu {
      overflow: hidden;
      width: 100%;
      padding: 0;
      margin: 0;
      .games-hd-menu {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        display: block;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      ul {
        width: 101.25%;
        margin: 0 -0.625%;
        padding: 0 0;
        margin: 0 0;
        list-style: none;
        li {
          height: 41px;
          line-height: 41px;
          border: 1px solid #0697cb;
          border-radius: 20px;
          text-align: center;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          color: #00a6ff;
          padding: 0 15px;
          font-size: 16px;
          font-weight: bold;
          box-sizing: border-box;
          list-style-type: none;
        }
        .menu {
          white-space: nowrap;
          overflow-x: overlay;
          position: relative;
          display: inline-block;
          min-width: 11rem;
          margin-right: 0.15rem;
          width: fit-content;
          float: unset;
          margin: 0 0.625% 1.25% 0.625%;
        }
        .current {
          background: #00a6ff;
          color: #fff;
        }
      }
    }
    .games-sub-menu {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      overflow-x: scroll;
      display: flex;
      margin-bottom: 25px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      li {
        white-space: nowrap;
        overflow-x: overlay;
        position: relative;
        display: inline-block;
        min-width: 11rem;
        margin-right: 15px;
        width: fit-content;
        height: 41px;
        line-height: 41px;
        border: 1px solid #0697cb;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        color: #00a6ff;
        padding: 0 15px;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        list-style-type: none;
      }
    }
    .games-panes {
      width: 100%;
      margin: 0 0;
      padding: 0 0;
      position: relative;
      .games-platform-item {
        position: relative;
        padding: 0;
        margin: 0;
        .clear {
          clear: both;
          height: 0;
          line-height: 0;
          font-size: 0px;
          display: block;
          overflow: hidden;
          display: inline-block;
        }
        .games-list {
          margin-top: 0;
          padding: 0;
          margin: 0;
          .clearfix {
            width: 100%;
            display: inline-block;
            padding: 0;
            margin: 0;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              float: left;
              width: 18.75%;
              margin: 0 3.1% 1.25%;
              border-radius: 10px;
              background: #fff;
              position: relative;
              overflow: hidden;
              list-style-type: none;
              img {
                height: 296px;
                width: 100%;
                background-position: top center;
                background-size: 100%;
                border-radius: 0 0 15px 15px;
                display: block;
              }
              .game-name {
                width: 100%;
                text-align: center;
                font-size: 20px;
                line-height: 1.2;
                text-align: center;
                color: #323232;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                  font-size: 14px;
                  color: #707070;
                  display: block;
                }
              }
              .game-rollover {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                a {
                  width: 100%;
                  height: 100%;
                  text-indent: -9999px;
                  cursor: pointer;
                  display: block;
                  color: #fff;
                  text-decoration: none;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-text {
    font-size: 16px;
    p {
      line-height: 1.3;
    }
    a {
      color: #00a6ff;
      text-decoration: underline;
      font-weight: bold;
    }
    .text-left {
      text-align: left;
    }
    .layout-height {
      height: 300px;
    }
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
      padding: 0;
    }
  }
  .games-text {
    padding: 60px 0 30px;
    max-width: 1400px;
    margin: 0 auto;
  }

  .page-text h1,
  .page-text h2,
  .page-text h3,
  .page-text h4,
  .page-text h5,
  .page-text h6 {
    font-weight: 600;
    color: #00a6ff;
  }

  .page-text h2,
  .page-text h3,
  .page-text h4,
  .page-text h5,
  .page-text h6 {
    text-align: left;
  }

  .page-text,
  .agency-content {
    font-size: 16px;
  }

  .page-text p,
  .agency-content p {
    padding-bottom: 15px;
    font-size: 16px;
    color: #404040;
  }

  .page-text b {
    color: #00a6ff;
  }

  .page-text .button {
    background-color: #fff;
    box-shadow: 0px 5px 10px #00000029;
    border: 4px solid #00a6ff;
    border-radius: 91px;
    color: #00a6ff;
    padding: 16px 32px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin: 1em auto;
    opacity: 1;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    display: block;
    width: 50%;
  }

  .button:hover {
    opacity: 0.9;
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  @media (max-width: 800px) {
    .row {
      flex-direction: column;
      margin: 0;
    }
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row [class*="col"] {
    position: relative;
  }
  /* FAQ */

  .faq details summary {
    color: #00a6ff;
    padding: 18px 7px;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #e3e3e3;
    position: relative;
    list-style: none;
    font-size: 20px;
    font-weight: 500;
  }

  .faq details summary:last-child {
    border-bottom: 1px solid #e3e3e3;
  }

  .faq details summary::after {
    position: absolute;
    background: url(../../assets/images/in/right.png)
      no-repeat scroll;
    right: 20px;
    width: 26px;
    height: 26px;
    content: " ";
  }

  .faq details summary::marker {
    display: none;
  }

  .faq details[open] {
    background-color: #f8f8f8;
  }

  .faq details[open] summary ~ * {
    -webkit-animation: sweep 0.5s ease-in-out;
    animation: sweep 0.5s ease-in-out;
  }

  .faq details[open] summary {
    background-color: #f8f8f8;
    padding: 18px 30px 15px;
    border-bottom: 1px solid #f8f8f8;
  }

  .faq details[open] summary::after {
    position: absolute;
    background: url(../../assets/images/in/down.png)
      no-repeat scroll;
    right: 20px;
    width: 26px;
    height: 26px;
    content: " ";
  }

  .faq details[open] .faq-content {
    border-top: 0px solid #e3e3e3;
  }

  .page-text .faq__content {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    border-top: 0px solid #e3e3e3;
    padding: 0 40px 10px 15px;
  }

  .page-text .faq__content p {
    margin: 0;
    color: #707071;
  }

  .faq .button {
    background-color: #00a6ff;
    color: #fff;
    padding: 16px 32px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 1em auto;
    cursor: pointer;
    display: inline-block;
    border: 1px;
    text-decoration: none;
    border-radius: 0;
  }
  /* FAQ */

  .download-app-row {
    align-items: center;
  }

  .download-app-row .col:first-child {
    text-align: right;
    padding-right: 10px;
  }

  .download-app-row .col:last-child {
    text-align: left;
    padding-left: 10px;
  }

  .page-text .text-left {
    text-align: left;
  }

  .mt-0 {
    margin-top: 0px;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .page-text .font-size-20 {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    .mb-20 {
      margin-bottom: 15px;
    }
    .mt-20 {
      margin-top: 15px;
    }
    .mb-30 {
      margin-bottom: 20px;
    }
    .mt-30 {
      margin-top: 20px;
    }
    .mb-40 {
      margin-bottom: 25px;
    }
    .mt-40 {
      margin-top: 25px;
    }
    .mb-50 {
      margin-bottom: 30px;
    }
    .mt-50 {
      margin-top: 30px;
    }
    .download-app-row .col:first-child,
    .download-app-row .col:last-child {
      text-align: center;
      padding: 0;
    }
    .faq details summary {
      padding: 18px 30px 18px 7px;
    }
    .faq details summary::after,
    .faq details[open] summary::after {
      right: 10px;
      width: 20px;
      height: 20px;
      background-size: cover;
      top: 50%;
      transform: translateY(-50%);
    }
    .page-text .faq__content {
      padding: 0 15px 10px;
    }
  }

  @media (min-width: 801px) {
    .page-text h1,
    .page-text h2,
    .page-text h3,
    .page-text h4,
    .page-text h5,
    .page-text h6 {
      font-size: 36px;
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
  }
}
.read-more {
  width: 241px;
  height: 41px;
  margin: 30px auto 0;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #00a6ff;
  line-height: 41px;
  text-align: center;
  color: #00a6ff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>